<!-- 问答分类 -->
<template>
  <div class="qacategory-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 店铺名-->
    <div class="detailTest-detail">
      <div class="detail-title">
        店铺名称: {{ shopDetail.shopName || "--" }}
      </div>
    </div>
    <!-- 搜索框 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>分类名称:</span>
        <el-input
          class="common-screen-input"
          v-model="params.typeName"
          placeholder="请填写问题名称"
        ></el-input>
      </div>

      <el-button type="primary" size="small" @click="select">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <!-- 新增问答按钮行 -->
    <div class="newadd">
      <el-button type="primary" size="mini" @click="addDrawer('add', 1, 0)"
        >新增一级分类
      </el-button>
    </div>
    <!-- 树形表格 -->
    <tree-table
      class="treeTable"
      :data="catelist"
      :columns="columns"
      :selection-type="false"
      :expand-type="false"
      :show-row-hover="false"
      :is-fold="false"
      children-prop="childrenList"
    >
      <!-- 操作 -->
      <template slot="opt" slot-scope="scope">
        <el-button
          v-if="scope.row.mainId == 0"
          type="text"
          size="mini"
          @click="addDrawer('add', 2, scope.row.id, scope.row)"
          >新增子分类
        </el-button>
        <el-button
          type="text"
          size="mini"
          @click="addDrawer('edit', scope.row.main_id, scope.row.id, scope.row)"
        >
          编辑
        </el-button>
        &nbsp;&nbsp;&nbsp;
        <el-popconfirm
          placement="top"
          title="确定删除吗？"
          @confirm="() => deleteItem(scope.row.id)"
        >
          <el-button type="text" size="small" slot="reference">删除</el-button>
        </el-popconfirm>
      </template>
    </tree-table>
    <qa-category-add-list
      ref="qaCategoryAddList"
      :drawer="drawer"
      :shopId="JSON.parse(this.$route.query.shopid)"
      @handleClose="handleClose"
    ></qa-category-add-list>
    <!-- 分页 -->
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getShopDetail } from "../../service/common.js";
import qaCategoryAddList from "./children/qaCategoryAddList";
import { categoryList, delCate } from "../../service/auto";
export default {
  name: "qaCategory",
  components: {
    Breadcrumb,
    qaCategoryAddList
  },
  props: {},
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "知识库", isLink: false },
        { title: "自动回复", isLink: true, url: "/library/auto-reply-list" },
        { title: "问答分类", isLink: false }
      ],
      //   分页
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      shopDetail: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        shopId: JSON.parse(this.$route.query.shopid)
      },
      autoList: [], //自动回复下拉
      classList: [], //所属分类下拉
      // 树形数据列表，默认为空
      catelist: [],
      // 树形数据子项
      childrenList: [],
      // 为table指定列的定义
      columns: [
        {
          label: "分类名称",
          prop: "typeName"
        },
        {
          label: "操作",
          // 表示，将当前列定义为模板列
          type: "template",
          // 表示当前这一列使用模板名称
          template: "opt",
          width: "620px"
        }
      ],
      drawer: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 分页
    pageFun(e, type) {
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getCategoryList();
    },
    addDrawer(type, level, id, row) {
      this.drawer = true;
      console.log(level, "level///");
      this.$refs.qaCategoryAddList.getInfo({ type, level, id }, row);
    },
    async deleteItem(id) {
      const res = await delCate({}, id);
      if (res.code != 200) return this.$message.erroe("删除失败！");
      this.$message.success("删除成功");
      this.getCategoryList();
    },
    handleClose() {
      this.drawer = false;
      this.getCategoryList();
    },
    // 获取店铺名
    async getShopDetail() {
      // 获取店铺详情
      let resData = (await getShopDetail({ shopId: this.$route.query.shopid }))
        .data;
      this.shopDetail = resData[0];
    },
    // 获取分类列表
    async getCategoryList() {
      try {
        let params = { ...this.params };
        const { data: res } = await categoryList(params);
        console.log(res);
        this.pagination.total = res.total;
        this.params.pageSize = res.size;
        this.catelist = res.records;
      } catch (error) {
        console.log(error);
      }
    },
    select() {
      this.getCategoryList();
    },
    reset() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        shopId: JSON.parse(this.$route.query.shopid)
      };

      this.getCategoryList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getShopDetail();
    this.getCategoryList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.detailTest-detail {
  display: flex;
  padding: 10px 10px 24px;
  align-items: center;
  color: #000000a6;
  .detail-title {
    margin-bottom: 16px;
    font-size: 14px;
  }
}
.newadd {
  display: flex;
  justify-content: space-between;
}
.zk-table {
  margin: 16px 0;
}
/deep/ .zk-table__header-cell {
  font-weight: 400 !important;
}
/deep/ .zk-table__header-row {
  background-color: transparent;
}
</style>
